import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () => import('@/components/Layout'),
    children:[
      {
        path: '/home',
        name: 'home',
        meta:{
          title: '首页'
        },
        component: () => import('@/views/Home')
      },
      {
        path: '/hospital',
        name: 'hospital',
        meta:{
          title: '体检机构'
        },
        component: () => import('@/views/Hospital')
      },
      {
        path: '/personal',
        name: 'personal',
        meta:{
          title: '个人中心'
        },
        component: () => import('@/views/Personal')
      }
    ]
  },
  {
    path: '/hospitalDetails',
    name: '医院详情',
    meta:{
      title: '医院详情'
    },
    component: () => import('@/views/hospitalMore/Details')
  },
  {
    path: '/hospitalPhysical',
    name: '套餐详情',
    meta:{
      title: '套餐详情'
    },
    component: () => import('@/views/hospitalMore/Physical')
  },
  {
    path: '/hospitalOrder',
    name: '立即预约',
    meta:{
      title: '立即预约'
    },
    component: () => import('@/views/hospitalMore/Order')
  },
  {
    path: '/hospitalPay',
    name: '支付订单',
    meta:{
      title: '支付订单'
    },
    component: () => import('@/views/hospitalMore/Pay')
  },
  {
    path: '/myOrder',
    name: '我的订单',
    meta:{
      title: '我的订单'
    },
    component: () => import('@/views/myOrder/index')
  },
  {
    path: '/setSystem',
    name: '设置',
    meta:{
      title: '设置'
    },
    component: () => import('@/views/system/SetSystem')
  },
  {
    path: '/personaloper',
    component: () => import('@/views/personalOper/index'),
    children: [
      {
        path: '/messageCenter',
        name: '消息中心',
        meta:{
          title: '消息中心'
        },
        component: () => import('@/views/personalOper/MessageCenter')
      },
      {
        path: '/profile',
        name: '个人档案',
        meta:{
          title: '个人档案'
        },
        component: () => import('@/views/personalOper/Profile')
      },
      {
        path: '/orderDetail',
        name: '订单详情',
        meta:{
          title: '订单详情'
        },
        component: () => import('@/views/myOrder/OrderDetail')
      }
    ]
  },
  {
    path: '/contact',
    name: '关于我们',
    meta:{
      title: '关于我们'
    },
    component: () => import('@/views/personalOper/Contact')
  },
  {
    path: '/customerChat',
    name: '在线客服',
    meta:{
      title: '在线客服'
    },
    component: () => import('@/views/chat/CustomerChat')
  },
  {
    path: '/expertChat',
    name: '专家咨询',
    meta:{
      title: '专家咨询'
    },
    component: () => import('@/views/chat/ExpertChat')
  },
  {
    path: '/groupInsp',
    name: '企业团检',
    meta:{
      title: '企业团检'
    },
    component: () => import('@/views/hospitalMore/GroupInspection')
  },
  {
    path: '/jkglService',
    name: '健康管理服务体系',
    meta:{
      title: '健康管理服务体系'
    },
    component: () => import('@/views/hospitalMore/JkglService')
  },
  {
    path: '/search',
    name: '搜索',
    meta:{
      title: '搜索'
    },
    component: () => import('@/views/hospitalMore/Search.vue')
  },
  {
    path: '/login',
    name: '登录/注册',
    meta:{
      title: '登录/注册'
    },
    component: () => import('@/views/login-register/index')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,form,next) => {
  if(to.meta.title){
    document.title = "深迈医健--测试版--" + to.meta.title
  }else{
    document.title = "深迈医健--测试版"
  }
  next()
})

export default router
