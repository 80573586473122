<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import store from '@/store/index';
import {websocketUrl} from '@/api/chat'
import { getUser } from '@/api/chat'
import {deleteRedis} from '@/api/user'
import {ElMessage} from "element-plus";
import { useRouter } from "vue-router";

const router = useRouter()

const websocket = ref(null)
const isLogin = computed({
  get(){
    return store.getters.JUDGE_USER
  },
  set(val){
    isLogin.value = val
  }
})
const initWebSocket = () => {
  if(!isLogin.value) return
  websocket.value = new WebSocket(websocketUrl(store.getters.GET_USER_ROLE,store.getters.GET_USER_ID))
  websocket.value.onopen = () => {
    console.log('webSocket open')
    initChatUser()
  }
  websocket.value.onerror = (err) => {
      console.error(err)
  }
  websocket.value.onmessage = (event) => {
    if(event.data == '订单已处理') return store.dispatch("PullMsg")
    let entity = JSON.parse(event.data)
    if(entity.code == '201') {
        sessionStorage.clear()
        websocket.value.close()
        ElMessage.error(entity.message)
        return router.replace('/login')
    }
    if(entity?.data?.from?.identity == 'ROLE_ADMIN'){
      store.commit('PUSH_MSG',entity.data)
    }else{
      store.commit('SET_NEW_MSG')
    }
  }
  websocket.value.onclose = () => {
    console.log("已断开链接")
  }
}
const initChatUser = () => {
  getUser(store.getters.GET_USER_ID).then(res => {
    store.commit('SET_CHAT_USER', res.data.data)
  })
}
onMounted(() => {
  if(isLogin.value) {
      if(window.performance.navigation.type == 1 && websocket.value == null) {
          initWebSocket()
      }
  }
  let beginTime = 0;
  let differTime = 0;
  window.onunload = () => {
    differTime = new Date().getTime() - beginTime
    if(differTime <= 5){
      deleteRedis().then(() => console.log('删除缓存'))
    }
  }
  window.onbeforeunload = () => {
    beginTime = new Date().getTime()
  }
})
watch(() => isLogin.value, () => {
  initWebSocket()
})
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
