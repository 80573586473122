import request from "@/util/request";

//用户登录
export const login = (data) => {
    return request({
        url: '/login',
        method: 'post',
        params: data
    })
}

//用户注册
export const register = (data) => {
    return request({
        url: '/tjyy/tjUser/register',
        method: 'post',
        data: data
    })
}

//退出登录
export const logout = () => {
    return request({
        url: '/logout',
        method: 'get'
    })
}

//删除reids缓存
export const deleteRedis = () => {
    return request({
        url: '/logout/deleteRedisById',
        method: 'get'
    })
}

//查询消息
export const getMsg = () => {
    return request({
        url: '/tjyy/tjUser/queryRemendByRedis',
        method: 'get'
    })
}

//查看消息并删除红点
export const deleteBandge = (orderNo) => {
    return request({
        url: '/tjyy/tjUser/removeRedis/' + orderNo,
        method: 'get'
    })
}

//发送验证码
export const sendCode = (phoneNumber) => {
    return request({
        url: '/sms/sendSms',
        method: 'post',
        params: {
            phoneNumber
        }
    })
}

//验证
export const verifyCode = (phoneNumber,code) => {
    return request({
        url: '/sms/verifyCode',
        method: 'post',
        params: {
            phoneNumber,
            code
        }
    })
}

//忘记密码
export const forgetPwd = (username,password) => {
    return request({
        url: '/tjyy/tjUser/forgetPwd',
        method: 'post',
        params: {
            username,
            password
        }
    })
}