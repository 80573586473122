export default {
    state: {
        // 当前用户信息
        user: {
            id: '',
            identity: '',
            name: ''
        },
        //聊天记录
        msgList: [],
        newMsg: 0
    },
    getters: {
        GET_CHAT_USER: state => state.user,
        GET_CHAT_USER_ID: state => state.user.id,
        GET_CHAT_MSG_LIST: state => state.msgList,
        GET_CHAT_NEW_MSG : state => state.newMsg
    },
    mutations: {
        SET_CHAT_USER: (state, val) => {
            state.user = val
        },
        SET_CHAT_MSG_LIST: (state,val) => {
            state.msgList = val
        },
        PUSH_MSG: (state,val) => {
            state.msgList.push(val)
        },
        SET_NEW_MSG: (state) => {
            state.newMsg++
        }
    },
    actions: {

    }
}
