import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from "@/router";

const request = axios.create({
    baseURL: "/api",
    timeout: 50000000
})

request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    return config
}, error => {
    return Promise.reject(error)
})

request.interceptors.response.use(response => {
    if(response.data.code === 405){
        ElMessage.error("请登录")
        localStorage.clear()
        router.push({path: '/login'})
    }
    if(response.data.code === 201){
        console.error(response.data.data)
    }
    return response
}, error => {
    let { message } = error
    if(message == "Network Error"){
        ElMessage.error("接口连接异常")
    }
    else if(message.includes("timeout")){
        ElMessage.error("系统请求超时,请稍后再试!")
    }
    return Promise.reject(error)
})

export default request
