import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import order from "./modules/order";
import chat from './modules/chat'

export default createStore({
  state: {
  },
  getters: {
    GET_FILE_URL: state => {
      return (url) => {
        if(url){
          return "/api" + '/file/' + url
        }else{
          return "/api" + '/file/' + "img404.png"
        }
      }
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    order,
    chat
  },
  plugins:[
      createPersistedState({
        storage: window.sessionStorage
      })
  ]
})
