import request from "@/util/request";

export const websocketUrl = (identity,id) => {
    return "ws://www.shenmaihealth.com/chat/" + identity + "/" + id
    // return "ws://localhost:8800/chat/" + identity + "/" + id
}

// 获取当前用户
export const getUser = (id) => {
    return request({
        url: '/chat/' + id,
        method: 'get'
    })
}

// 获取所有专家
export const getAllDoctor = () => {
    return request({
        url: '/tjyy/tjUser/findAllDocker',
        method: 'get'
    })
}

// 获取在线专家
export const getOnlineByDoctor = () => {
    return request({
        url: '/chat/online/list/' + 'ROLE_DOCTOR',
        method: 'get'
    })
}

// 获取在线用户
export const getOnlineByUser = () => {
    return request({
        url: '/chat/online/list/' + 'ROLE_USER',
        method: 'get'
    })
}

// 向随机客服发信息
export const pushCustomer = (message) => {
    return request({
        url: "/chat/pushCustomer",
        method: 'post',
        data: message
    })
}

// 获取聊天记录
export const selfMsg = (id) => {
    return request({
        url: "/chat/selfAllList/" + 'ROLE_ADMIN' + "/" + id,
        method: 'get'
    })
}

// 获取用户与专家的聊天记录
export const selfExpert = (fromId,toId) => {
    return request({
        url: "/chat/self/" + fromId + '/' + toId,
        method: 'get'
    })
}

// 向专家发信息
export const pushExpert = (id,message) => {
    return request({
        url: '/chat/push/' + id,
        method: 'post',
        data: message
    })
}