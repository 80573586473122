import {login, logout, getMsg} from "@/api/user";
import store from "@/store";

export default {
    state:{
        userinfo:{},
        orderMsgList: [],
        proFileIndex: 0
    },
    getters:{
        JUDGE_USER: (state) => {
            return Object.keys(state.userinfo).length
        },
        GET_USER_ID: state => state.userinfo.id,
        GET_USER_NAME: state => state.userinfo.name,
        GET_USER_USERNAME: state => state.userinfo.username,
        GET_USER_ROLE: state => state.userinfo.role,
        GET_PRO_FILE_INDEX: state => state.proFileIndex,
        BOOLEAN_MSG: state => {
            return (orderNo) => {
                return  state.orderMsgList.includes(orderNo)
            }
        },
        IS_MSG: state => {
            return state.orderMsgList.length === 0
        }
    },
    mutations:{
        SET_USER_INFO: (state,val) => {
            state.userinfo = val
        },
        SET_ORDER_MSG_LIST: (state, val) => {
            state.orderMsgList = val
        },
        SET_PRO_FILE_INDEX: (state, val) => {
            state.proFileIndex = val
        }
    },
    actions:{
        Login({ commit }, loginForm){
            return new Promise((resolve, reject) => {
                login(loginForm).then(res => {
                    if(res.data.code == 200){
                        commit('SET_USER_INFO',res.data.data)
                        resolve("登录成功!")
                    }else{
                        reject(res.data.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Logout({ commit }){
            return new Promise((resolve, reject) => {
                logout().then(res => {
                    if(res.data.code == 200){
                        commit('SET_USER_INFO',{})
                        resolve("已退出登录!")
                    }else{
                        reject(res.data.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        PullMsg({ commit,state }){
            return new Promise((resolve, reject) => {
                getMsg().then(res => {
                    if(res.data.code == 200){
                        let arr = []
                        for(let key in res.data.data){
                            if(JSON.parse(res.data.data[key]) == state.userinfo.username){
                                arr.push(key.slice(0,-7))
                            }
                        }
                        commit('SET_ORDER_MSG_LIST',arr)
                        resolve()
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
