import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import './assets/scss/index.scss'
import './assets/font/font.scss'

import { useIntersectionObserver } from '@vueuse/core'

const app = createApp(App)
app.directive('lazy',{
    mounted(el,binding){
        // console.log(el,binding.value,'0000');
        const { stop } = useIntersectionObserver(
            el,
            ([{ isIntersecting }], observerElement) => {
            //   console.log(isIntersecting,'isIntersecting');
              if(isIntersecting){
                el.src = binding.value
                stop()
              }
            },
          )
    }
})

app.use(store).use(router).use(ElementPlus,{locale: zhCn})
app.mount('#app')
