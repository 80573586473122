export default {
    state: {
        isOrderList: true
    },
    getters: {
      GET_IS_ORDER_LIST: state => state.isOrderList
    },
    mutations: {
        SET_IS_ORDER_LIST: (state,val) => {
            state.isOrderList = val
        }
    }
}
